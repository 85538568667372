import { default as callbackJaJ8FDMuzLMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/airtable/callback.vue?macro=true";
import { default as _91slug_93yLrtRnKcCUMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/features/[slug].vue?macro=true";
import { default as index7XhErBX5rpMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/features/index.vue?macro=true";
import { default as editCljQw8OHPSMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexWQUJGM6e0sMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsfq25i64AtCMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexb2BLIJvxkUMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexEpIfpeffKIMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareUkMoXDl9D1Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showhOqkM2w78uMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexyweVAHbfqxMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/create/index.vue?macro=true";
import { default as homev8HWi4eaicMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/home.vue?macro=true";
import { default as indexsy88G1oxfqMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/index.vue?macro=true";
import { default as login5rWraVHOj7Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/login.vue?macro=true";
import { default as callbackNnIlpueRYtMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/oauth/callback.vue?macro=true";
import { default as onboardingA5gxe2spMeMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/onboarding.vue?macro=true";
import { default as _91token_93T7cPTvuLMzMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexFwhK0Eg2fMMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingI7tNTe3Wo7Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policybjpD7mygpOMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/privacy-policy.vue?macro=true";
import { default as register6N01zQmCJ2Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/register.vue?macro=true";
import { default as access_45tokensjiGPoXFIkYMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountNtGvQCSdUlMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/account.vue?macro=true";
import { default as adminQJTpueepLlMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/admin.vue?macro=true";
import { default as billingqEGzpCDiI4Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionslTjzZbzuW5Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domain5YHGqj42MzMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsXvCPHsc1ygMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexPlztsgkuIgMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/index.vue?macro=true";
import { default as passwordJ7JqU3T4NuMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/password.vue?macro=true";
import { default as profilewFHcwCTx6MMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/profile.vue?macro=true";
import { default as workspaces7Gu9mqN4mRMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsImXBbfVBJ0Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/settings.vue?macro=true";
import { default as errorVGRao882HkMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successDBpngl4Gg4Meta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsc0SoFMQHauMeta } from "/codebuild/output/src3951285042/src/airtableforms/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "airtable-callback",
    path: "/airtable/callback",
    meta: callbackJaJ8FDMuzLMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/airtable/callback.vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editCljQw8OHPSMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showhOqkM2w78uMeta?.name,
    path: "/forms/:slug()/show",
    meta: showhOqkM2w78uMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show-analytics",
    path: "analytics",
    meta: analyticsfq25i64AtCMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexb2BLIJvxkUMeta?.redirect,
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexEpIfpeffKIMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareUkMoXDl9D1Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexyweVAHbfqxMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homev8HWi4eaicMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login5rWraVHOj7Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingA5gxe2spMeMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93T7cPTvuLMzMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: indexFwhK0Eg2fMMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: register6N01zQmCJ2Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsImXBbfVBJ0Meta?.name,
    path: "/settings",
    meta: settingsImXBbfVBJ0Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountNtGvQCSdUlMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminQJTpueepLlMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingqEGzpCDiI4Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionslTjzZbzuW5Meta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-custom-domain",
    path: "custom-domain",
    meta: custom_45domain5YHGqj42MzMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: "settings-email-settings",
    path: "email-settings",
    meta: email_45settingsXvCPHsc1ygMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexPlztsgkuIgMeta?.redirect,
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordJ7JqU3T4NuMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilewFHcwCTx6MMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspaces",
    path: "workspaces",
    meta: workspaces7Gu9mqN4mRMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorVGRao882HkMeta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successDBpngl4Gg4Meta || {},
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/codebuild/output/src3951285042/src/airtableforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]