import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

export const useAirtableUsersStore = defineStore('airtable_users', () => {
    const contentStore = useContentStore()
    const allLoaded = ref(false)
    const loadAllRequest = ref(null)

    const loadAll = async (userFetchUrl) => {
        if (loadAllRequest.value) {
            return loadAllRequest.value
        }
        if (!userFetchUrl) {
            return
        }
        contentStore.startLoading()
        // eslint-disable-next-line
        loadAllRequest.value = new Promise(async (resolve, reject) => {
            try {
                const data = await airtableFormsFetch(userFetchUrl)
                const users = data.sort((a, b) => {
                    return a.name > b.name ? 1 : -1
                })
                contentStore.save(users)
                contentStore.stopLoading()
                allLoaded.value = true
                loadAllRequest.value = null

                resolve()
            } catch (err) {
                useAlert().error('Error loading users.')
                contentStore.stopLoading()
                loadAllRequest.value = null
                reject(err)
            }
        })
    }

    return {
        ...contentStore,
        loadAll,
        allLoaded,
    }
})